<template>
  <v-container id="crud" fluid tag="section">
    <v-row>
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              Filtros
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <hr/>
              <br/>

              <v-row class="align-end">
                <v-col cols="3">
                  <v-text-field
                    v-model="searchFilters.username"
                    label="Nombre de usuario"
                    single-line
                    hide-details
                    outlined
                    filled
                    dense
                    rounded
                    class="filtros-trans"
                  />
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    v-model="searchFilters.firstName"
                    label="Nombre"
                    single-line
                    hide-details
                    outlined
                    filled
                    dense
                    rounded
                    class="filtros-trans"
                  />
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    v-model="searchFilters.lastName"
                    label="Apellido"
                    single-line
                    hide-details
                    outlined
                    filled
                    dense
                    rounded
                    class="filtros-trans"
                  />
                </v-col>

                <!-- Botón Buscar -->
                <v-col cols="12" sm="3" class="d-flex align-center py-4">
                  <v-btn color="primary" small @click="applyFilters">
                    Buscar
                    <v-icon right>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <br/>
              <br/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row class="mb-3">
      <v-col class="d-flex justify-end" style="margin-right: 20px;">

          <v-btn
          color="green" small dark

          @click="addNewUser">
            Agregar Usuario
            <v-icon right>mdi-account-plus</v-icon>
          </v-btn>

      </v-col>
    </v-row>


    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="filteredData"
          :search="search"
          sort-by="username"
          class="elevation-1"
          :footer-props="{
            'items-per-page-options': [10, 20],
          }"
        >
          <template v-slot:[`item.firstName`]="{ item }">
            {{ item.firstName + " " + item.lastName }}
          </template>
          <template v-slot:[`item.createdTimestamp`]="{ item }">
            {{ $formatDateFromTimestamp(item.createdTimestamp) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-row class="">
              <v-col cols="6" md="6" sm="6" style="padding: 6px 0px 0px 0px">
                <v-menu
                  bottom
                  origin="center center"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="transparent elevation-0"
                      dark
                      small
                      v-bind="attrs"
                      v-on="on"
                      min-width="30px"
                    >
                      <v-icon
                        color="black"
                        style="font-size: 25px"
                      >
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-icon class="mr-0">
                        <v-icon
                          small
                          class="mr-2"
                          :color="$cv('btnVista')"
                          title="Ver"
                        >
                          mdi-eye-outline
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Ver</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      @click="
											vista = false;
											editItem(item.id);
										"
                    >
                      <v-list-item-icon class="mr-0">
                        <v-icon
                          small
                          class="mr-2"
                          :color="$cv('btnEditar')"
                          title="Editar"
                        >
                          mdi-pencil
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Editar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="$can([titlePerms + '_delete'])"
                      @click="$deleteItem(item.id, item.username)"
                    >
                      <v-list-item-icon class="mr-0">
                        <v-icon
                          small
                          class="mr-2"
                          :color="$cv('btnEliminar')"
                          title="Eliminar"
                        >
                          mdi-delete
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Eliminar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="$can([titlePerms + '_changestatus'])"
                      @click="$enabledItem(item)"
                    >
                      <v-list-item-icon class="mr-0">
                        <v-icon
                          medium
                          class="mr-2"
                          :color="
                            item.enabled == false
                              ? $cv('btnActivo')
                              : $cv('btnInactivo')
                          "
                          :title="
                            item.enabled == false
                              ? $cv('titleActivar')
                              : $cv('titleDesactivar')
                          "
                        >
                          mdi-archive-arrow-down
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{
                            item.enabled == false
                              ? $cv("titleActivar")
                              : $cv("titleDesactivar")
                          }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="$can([titlePerms + '_list_sesiones'])"
                      @click="getSessions(item)"
                    >
                      <v-list-item-icon class="mr-0">
                        <v-icon
                          small
                          class="mr-2"
                          :color="$cv('principal')"
                          title="Consultar Sesiones"
                        >
                          mdi-security
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Consultar Sesiones</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogFormUser" :key="keyDialogFormUser" max-width="85%" persistent @update:modelValue="resetForm">
      <v-card>
        <v-toolbar dark color="primary">
            <v-btn class="ml-1" icon dark @click="dialogFormUser = false">
              <v-icon >mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
                {{ title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>


          </v-toolbar>
        <v-form ref="form" v-model="valid" lazy-validation  autocomplete="off">
          <!--
          <v-toolbar dark color="primary">
            <v-btn icon dark  @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{
              editedIndex != -1
                ? formTitle + ": " + editedItem.username
                : formTitle
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text

                v-if="!vista"
                :disabled="!valid"
                @click="save()"
                >Guardar</v-btn
              >
            </v-toolbar-items>
          </v-toolbar>
          -->

          <v-card-text style="overflow-y: scroll; max-height: 65vh">


            <v-row>
 <v-col cols=7  >
              <v-card outlined class="mt-6 pt-0 pb-1 mb-1 card-border" style="height: 333px;">
                <span class="card-span">Información Personal</span>
                <v-card-text>

                      <v-row  class="mt-1 mx-1">
                        <v-col
                          cols="12"
                          :md="editedIndex == -1 ? 6 : 4"
                          :sm="editedIndex == -1 ? 6 : 4"
                          class="pa-0 px-2"
                        >
                          <div class="d-flex flex-column">
                            Nombres
                            <v-text-field
                              v-model="editedItem.firstName"
                              label=""
                              outlined
                              dense
                              :disabled="vista"
                              :rules="[
                                $rulesRequerido,
                                $rulesAlfaNum,
                                $rulesMax500,
                              ]"
                            ></v-text-field>
                          </div>

                        </v-col>
                        <v-col
                          cols="12"
                          :md="6"
                          :sm="6"
                          class="pa-0 px-2"
                        >
                          <div class="d-flex flex-column">
                            Apellido
                            <v-text-field
                              v-model="editedItem.lastName"
                              label=""
                              outlined
                              dense
                              :disabled="vista"
                              :rules="[
                                $rulesRequerido,
                                $rulesAlfaNum,
                                $rulesMax500,
                              ]"
                            ></v-text-field>
                          </div>
                        </v-col>
                        <v-col
                          cols="12"

                          sm="12"
                          class="pa-0 px-2"
                        >
                          <div class="d-flex flex-column">
                            Email
                            <v-text-field
                            v-model="editedItem.email"
                            outlined
                            dense
                            :disabled="vista"
                            label=""
                            :rules="emailRules"
                            :error-messages="customErrorEmailMessage"
                          ></v-text-field>
                          </div>

                        </v-col>
                      </v-row>



                  <v-row   class="mx-1">
                    <v-col
                      cols="12"

                      sm="6"
                      class="pa-0 px-2"
                    >
                      <div class="d-flex flex-column">
                        Contraseña
                        <v-text-field
                          type="password"
                          :disabled="vista"
                          v-model="editedItem.password"
                          label=""
                          outlined
                          dense
                          :rules=" (editedIndex===-1)? [$rulesRequerido, passwordMatchRule]: [passwordMatchRule]"
                           autocomplete="new-password"
                        ></v-text-field>
                      </div>

                    </v-col>

                    <v-col
                      cols="12"

                      sm="6"
                      class="pa-0 px-2"
                    >
                      <div class="d-flex flex-column">
                        Repetir Contraseña
                          <v-text-field
                            type="password"
                            :disabled="vista"
                            v-model="editedItem.password_confirm"
                            label=""
                            outlined
                            dense
                            :rules=" (editedIndex===-1)? [$rulesRequerido, passwordMatchRule]: [passwordMatchRule]"
                          ></v-text-field>
                        </div>

                    </v-col>
                  </v-row>
                </v-card-text>
               </v-card>
 </v-col>
<v-col cols=5>

               <v-card outlined class="mt-6 pt-0 pb-1 mb-1 card-border" style="height: 333px;">
                <span class="card-span">Roles Por División / País</span>
                <v-card-text>


                  <v-row   class="mt-1 mx-1">
                    <v-col cols="12" sm="12" class="pa-0 pl-1 pr-0">
                      <div class="d-flex flex-column">
                        <span>Rol<v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    class="ml-2"
                    color="default"
                    style="
                      height: 18px;
                      width: 18px;
                      font-size: 10px;
                      background-color: black;
                    "
                  >
                    <i class="fa fa-info" aria-hidden="true"></i>
                  </v-btn>
                </template>
                <span>Si selecciona un rol distinto de Administrador, se utilizará el nombre de usuario de SAP</span>
              </v-tooltip></span>
                        <v-autocomplete
                            :items="rolesPortal"
                            item-value="id"
                            item-text="nombre"
                            label=""
                            v-model="editedItem.role"
                            :rules="[v => !!v || 'Este campo es obligatorio']"
                            return-object
                            @change="requireCodigoSocio"
                            persistent-hint
                              class="hint-celeste"
                            outlined
                            dense
                          ></v-autocomplete>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 mx-1">
                    <v-col v-if="isSocioNegocio && isSocioNegocio == 1" cols="12" sm="10" class=" pl-1 pr-0">

                        Codigo de Cliente
                        <v-text-field
                          v-model="codigo"
                          placeholder="Ingrese código de cliente"
                          label=""
                          :rules="[$rulesRequerido]"
                          @click:append="getUserData()"
                          outlined
                          dense

                        ></v-text-field>



                    </v-col>
                    <v-col v-if="isSocioNegocio && isSocioNegocio == 2" cols="12" sm="10" class=" pl-1 pr-0">

                        Delegaciones aduana


                            <v-autocomplete
                            :items="delegacionesAduana"
                            item-value="CardCode"
                            :item-text="formatItemText"
                            label=""
                            outlined
                            dense
                            placeholder="Seleccione una delegación"
                            v-model="editedItem.delegacionAduana"
                            :rules="[$rulesRequerido]"
                            return-object

                            @change="(editedIndex===-1)?editedItem.username = null:''"
                          ></v-autocomplete>



                    </v-col>
                    <v-col v-if="isSocioNegocio && isSocioNegocio == 3"  cols="12" sm="10" class=" pl-1 pr-0">

                        Delegaciones ANMAC
                        <v-autocomplete
                        :items="delegacionesAnmac"
                        item-value="Code"
                        :item-text="formatItemText"
                        label=""
                        outlined
                        dense
                        placeholder="Seleccione una delegación"
                        v-model="editedItem.delegacionAnmac"
                        :rules="[$rulesRequerido]"
                        return-object                        

                        @change="(editedIndex===-1)?editedItem.username = null:''"
                      ></v-autocomplete>





                    </v-col>


                    <v-col v-if="isSocioNegocio" cols="12"  sm="1" class="pa-0 pl-1 ml-0 mt-3">
                      <br>

                      <v-btn  :color="validarUsuarioColor"   @click="fetchUserData()" class="capitalize-first custom-btn2 px-0 mt-0 mx-0 ml-0 pl-0">

                        <v-icon class="pl-0 ml-0 pr-0 pl-0" :class="validarUsuarioIcono"></v-icon>
                      </v-btn>


                      </v-col>
                  </v-row>
                  <v-row   class="mt-0 ml-1 mr-1 pt-0">
                    <v-col cols="12" sm="12" class=" pl-1 pr-0">
                      <div class="d-flex flex-column">
                        Nombre de Usuario
                        <v-text-field
                          v-model="editedItem.username"
                          label=""
                          outlined
                          dense
                          :rules="usernameRules"
                          :error-messages="customErrorUsernameMessage"
                          :title="
                            editedIndex != -1
                              ? 'El nombre de usuario no se puede editar'
                              : ''
                          "
                          :readonly="(isSocioNegocio!=false)?true: false"

                        ></v-text-field>
                      </div>

                    </v-col>
                  </v-row>
                </v-card-text>
               </v-card>
 </v-col>
            </v-row>



          </v-card-text>
          <v-card-actions>

            <v-spacer></v-spacer>


            <v-btn  color="white" @click="closeDialog" class="capitalize-first custom-btn">
              Cancelar
            </v-btn>




            <v-btn v-if="isSocioNegocio"  color="primary" :disabled="!(valid  &&   nombreUsuarioValido)" @click="save()" class="capitalize-first custom-btn">

            Guardar
            </v-btn>
            <v-btn v-else  color="primary" :disabled="!(valid )" @click="save()" class="capitalize-first custom-btn">

              Guardar
              </v-btn>

          </v-card-actions>

        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>

    <vue-confirm-dialog></vue-confirm-dialog>


    <v-dialog v-model="dialogHistorial" max-width="70%" persistent>
      <v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="dialogHistorial = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ titleHistorial }}</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text style="overflow-y: scroll; max-height: 72vh">
            <v-data-table
              :headers="headersHistorial"
              :items="historial"
              sort-by="start"
              class="elevation-1"
            >
              <template v-slot:[`item.start`]="{ item }">
                {{ $formatDateFromTimestamp(item.start) }}
              </template>
              <template v-slot:[`item.lastAccess`]="{ item }">
                {{ $formatDateFromTimestamp(item.lastAccess) }}
              </template>
              <template v-slot:[`item.clients`]="{ item }">
                {{ JSON.stringify(item.clients) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <dialog-confirmar
      v-model="dialogs['emularUsuario']"
      @input="dialog = $event"
      title="Infomación de Usuarios"
      :info="textInfoDialog"
      icon="mdi-alert-circle-outline"
      color="#1976D2"
      @confirm="setUser()"
    />
    <dialog-validate
      v-model="dialogs['userExists']"
      @input="dialog = $event"
      title="Error al consultar datos del usuario"
      info="El código de cliente ingresado no arroja resultados en SAP"
      icon="mdi-alert-circle-outline"
      color="#ffc107"
    />
    <dialog-validate
      v-model="dialogs['userPortalExists']"
      @input="dialog = $event"
      title="Usuario existente"
      info="El usuario seleccionado ya existe en Portal. Verifique la información"
      icon="mdi-alert-circle-outline"
      color="#ffc107"
    />

  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import DialogConfirmar from "@/views/dialogs/Confirmar.vue";
import DialogValidate from "@/views/dialogs/Validate.vue";

import Loading from "@/views/components/Loading.vue";

function title() {
  return "Usuarios";
}

export default {
  components: {
    Loading,
    DialogConfirmar,
    DialogValidate
  },
  data: (vm) => ({
    valid: true,
    title: title(),
    route: "usuarios",
    routeStore: "storeUserPortal",
    titlePerms: title().toLowerCase(),
    routeSessions: "usuarios_sessions",
    routeRolesByClient: "roles_client",
    dialogFormUser: false,
    dialogHistorial: false,
    snackbar: false,
    visible: true,
    text: "Registro Insertado",
    color: "success",
    timeout: 4000,
    search: "",
    searchFilters: {
      username: "",
      firstName: "",
      lastName: "",
    },
    vista: false,
    headers: [
      {text: "Usuario", value: "username", sortable: false},
      {text: "Nombre", value: "nombre", sortable: false},
      /*{text: "Delegación", value: "", sortable: false},*/
      {text: "Correo electrónico", value: "email", sortable: false},
      {text: "Modificado por", value: "", sortable: false},
      {text: "Acciones", value: "actions", sortable: false},
    ],
    titleHistorial: "",
    headersHistorial: [
      {text: "Id Sesion", value: "id", align: "center"},
      {text: "Fecha", value: "start", align: "center"},
      {text: "Último Acceso", value: "lastAccess", align: "center"},
      {text: "IP", value: "ipAddress", align: "center"},
      {text: "Producto", value: "clients", align: "center"},
    ],
    historial: [],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      enabled: "",
      totp: "",
      clients: "",
      entidades: "",
      role:"",
      delegacionAduana:"",
      delegacionAnmac:"",
      codigo:"",
    },
    defaultItem: {
      id: "",
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      enabled: "",
      totp: "",
      clients: "",
      entidades: "",
      role:"",
      delegacionAduana:"",
      delegacionAnmac:"",
      codigo:"",
    },

    filters: {
      username: "",
      firstName: "",
      lastName: "",
      enabled: "",
      nombre: "",
    },
    rolesPortal:[],
    //codigo: 'C30715948334',
    codigo: null,
    isSocioNegocio:false,
    dialogs: {
      emularUsuario: false,
      userExists: false,
      userPortalExists:false
    },
    textInfoDialog:'',
    routeBp: "bussinessPartnerClient",
    userSAP:{},
    delegacionesAnmac:[],
    delegacionesAduana:[],
    routeDelegacionesAnmac:'delegacionesAnmac',
    routeDelegacionesAduana:'delegacionesAduana',
    customErrorUsernameMessage: "", // Mensaje de error personalizado
    customErrorEmailMessage: "", // Mensaje de error personalizado
    keyDialogFormUser: 0,
    nombreUsuarioValido:false,
    passwordValido:false,
    validarUsuarioIcono: "mdi mdi-account-search",
    validarUsuarioColor: "primary"
  }),

  computed: {
    usernameRules() {
      return this.editedIndex === -1 ? [
                      this.$rulesRequerido,
                      this.$rulesAlfaNum,
                      this.$rulesMax500,
                      this.ruleUniqueUsername
                    ] : [this.$rulesRequerido,
                      this.$rulesAlfaNum,
                      this.$rulesMax500,
                    this.ruleUniqueUsernameEdition];
    },
    emailRules() {
      return this.editedIndex !== -1 ? [this.$rulesRequerido, this.$rulesMail, this.ruleUniqueEmailEdition] : [this.$rulesRequerido, this.$rulesMail, this.ruleUniqueEmail];
    },
    passwordMatchRule() {
      return v => {
        //if (!v) return 'Este campo es obligatorio';
        if(v){
          if (this.editedItem.password && this.editedItem.password_confirm) {
            this.passwordValido=false;
            return this.editedItem.password === this.editedItem.password_confirm || 'Las contraseñas no coinciden';
          }
        }

        this.passwordValido=true;
        return true;
      };
    },
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar " + this.title
        : this.vista == false
          ? "Editar " + this.title
          : this.title;
    },
    filteredData() {
      return this.$filteredData().data !== undefined
        ? this.$filteredData().data
        : this.$filteredData();
    },
  },

  watch: {
    async dialogFormUser(val) {
      if (!val) {
        this.$close();
      } else {
        try {
          this.identifiertypes = [
            {id: 1, name: "CUIL"},
            {id: 2, name: "CUIT"},
          ];
        } catch (error) {
          Swal.close();
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";
        }
      }
    },
  },

  created() {
    this.$initialize();
    //this.$getData(["clients", "entidades", "rolesPortal"]);
    this.$getData(["rolesPortal"]);
    this.getDelegacionesAnmac();
    this.getDelegacionesAduana();

  },

  methods: {


    getRoleData(id) {
        return this.rolesPortal.find(item => item.id === id) || null;
    },


    async editItem(id) {
      let vm = this;
      Swal.alertGetInfo("Obteniendo información <br><b></b>");
      try {
          let r = await vm.$axiosApi.getById(vm.route, id);

          if (r.data && r.data.code == 200) {
              vm.editedIndex = vm.route !== 'roles' ? r.data.data.id : r.data.data.name;
              vm.editedItem = Object.assign({}, r.data.data);
              vm.dialogFormUser = true;

              if (r.data.data.userRoles?.[0]?.role?.[0]) {
                  let role = r.data.data.userRoles[0].role[0];

                  //vm.editedItem.role = { id: role.idRolePortal, nombre: role.nombre };
                  vm.editedItem.role = this.getRoleData(role.idRolePortal);

                  let socio = await this.requireCodigoSocio();



                  if (socio) {
                    let delegationData = '';
                    if(role.delegacion){
                      const jsonString = role.delegacion;
                      delegationData  = JSON.parse(jsonString);
                    }
                      switch (socio){
                        case 1:
                          vm.editedItem.codigo = editedItem.username;
                        break;
                        case 2: //aduana
                          vm.editedItem.delegacionAduana = { CardCode: delegationData.code || '', CardName: delegationData.name || '' };
                        break;
                        case 3: //anmac
                          vm.editedItem.delegacionAnmac = { Code: delegationData.code || '', Name: delegationData.name || '' };
                        break;
                      }
                      vm.editedItem = { ...vm.editedItem }; //forzar la reactidad
                      console.log(vm.editedItem.delegacionAduana);

                  }
              }
          } else {
              vm.snackbar = true;
              vm.text = `<p>Ocurrió un problema al recuperar el registro.<br><hr><b>Codigo:</b> ${r.data.code} - <b>Error:</b> ${r.data.message}</p>`;
              vm.color = "error";
          }
      } catch (error) {
          console.error(error);
      } finally {
          Swal.close();
      }
  },

    async save() {
      let vm = this;
      console.log(vm.editedItem);
      console.log(vm.$refs.form.validate());
      //return;
      if (vm.$refs.form.validate()) {

          if (vm.editedIndex != -1) {
            Swal.alertGetInfo("Actualizando información");

            await vm.$axiosApi.put(vm.routeStore, vm.editedIndex, vm.editedItem)
              .then((r) => {
                  Swal.close();
                  vm.$respuesta(vm, r, 2);
                  this.closeDialog();

              }).catch((e) => {
                  Swal.close();
                  vm.$respuesta(vm, e, 2, true);
                  this.closeDialog();

              });

          } else {
            Swal.alertGetInfo("Registrando información");

            await vm.$axiosApi.post(vm.routeStore, vm.editedItem)
              .then((r) => {
                  Swal.close();
                  vm.$respuesta(vm, r, 1);
                  this.closeDialog();
              }).catch((e) => {
                console.log(e);
                  Swal.close();
                  vm.$respuesta(vm, e, 1, true);
                  this.closeDialog();
              });
          }
          Swal.close();

      }
    },

    closeDialog() {
      this.dialogFormUser = false;
      this.resetForm();
    },
    resetForm() {
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }; // Restablece los datos
        this.valid = false; // Reinicia validaciones
        this.isSocioNegocio = false;
        this.userSAP = {};
        this.keyDialogFormUser++; // Fuerza la recreación del diálogo
        this.$refs.form?.reset(); // Resetea el formulario
        this.$refs.form?.resetValidation(); // Reinicia validaciones de Vuetify
      });
    },
    addNewUser(){
      this.keyDialogFormUser++;
      this.dialogFormUser = true;
    },
    async getDelegacionesAnmac(){
      await this.$axiosApi
        .get(this.routeDelegacionesAnmac)
        .then((r) => {
          if (r.data) {
            this.delegacionesAnmac = r.data.data;
          }
        })
        .catch(function (error) {
          Swal.close();
          console.log(error);
        });
    },
    async getDelegacionesAduana(){
      await this.$axiosApi
        .get(this.routeDelegacionesAduana)
        .then((r) => {
          if (r.data) {
            this.delegacionesAduana = r.data.data;
          }
        })
        .catch(function (error) {
          Swal.close();
          console.log(error);
        });
    },
    async setUser(){
      try{
        let username = this.userSAP.CardCode;
        let resp = '';
        if(this.editedIndex === -1){
          resp = await this.$isUserByUsername(username);
            if (resp === true) {
              this.dialogs.userPortalExists = true;
            }
            else{
              this.editedItem.username = username;
              this.nombreUsuarioValido =true;
              this.validarUsuarioIcono= "mdi mdi-check";
              this.validarUsuarioColor= "success";
            }
            //else if(this.editedIndex === -1){

            //}
        }
        else{
          resp = await this.$isUserByUsernameEdition(username);

          if (resp === false) {
            this.editedItem.username = username;
            this.nombreUsuarioValido =true;
            this.validarUsuarioIcono= "mdi mdi-check";
            this.validarUsuarioColor= "success";
          }
          else if (resp == this.editedIndex){
            this.editedItem.username = username;
            this.nombreUsuarioValido =true;
            this.validarUsuarioIcono= "mdi mdi-check";
            this.validarUsuarioColor= "success";
          }
          else{
            this.dialogs.userPortalExists = true;
            this.editedItem.username = null;

          }



        }



      }
      catch(e){
        console.log(e);
        Swal.close();
      }


    },
    applyFilters() {
      this.filters = {...this.searchFilters};
      this.loadPlanilla();
    },
    filterUsers() {
      this.search =
        (this.filters.username || "") +
        " " +
        (this.filters.firstName || "") +
        " " +
        (this.filters.lastName || "");
    },

    loadPlanilla() {
      this.search =
        (this.filters.username || "") + " " +
        (this.filters.firstName || "") + " " +
        (this.filters.lastName || "");
    },





    getSessions(item) {
      let id = item.id;
      this.$axiosApi
        .getById(this.routeSessions, id)
        .then((r) => {
          this.titleHistorial = "Consulta de Sesiones de " + item.username;
          this.historial = r.data.data;
          this.dialogHistorial = true;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";
        });
    },

    // Filtros nativos
    filterByUsername(item) {
      return this.$filterBy(item, "username");
    },
    filterByFirstName(item) {
      return this.$filterBy(item, "firstName");
    },
    filterByNombre(item) {
      return this.$filterBy(item, "nombre");
    },
    filterByLastName(item) {
      return this.$filterBy(item, "lastName");
    },
    filterByEnabled(item) {
      return this.$filterBy(item, "enabled");
    },

    async requireCodigoSocio() {
      this.editedItem.username ='';
      this.validarUsuarioColor ='primary';
      this.validarUsuarioIcono ='mdi mdi-account-search';
      if(this.editedItem.role){
          const isSocio = () => {
          const rolesSocios = this.$RolesSocios;
          const rolesAduana = this.$RolesAduana;
          const rolesAnmac = this.$RolesAnmac;

          let nombre = this.editedItem.role.nombre.toUpperCase();
          nombre = this.$cleanText(nombre);
          if(rolesSocios.includes(nombre)){
            return 1;
          }
          if(rolesAduana.includes(nombre)){
            return 2;
          }
          if(rolesAnmac.includes(nombre)){
            return 3;
          }

          return false;
          //return valores.includes(nombre);
        };

        let response = await isSocio();
        this.isSocioNegocio = response;
        return response;
      }
      else{
        return false;
      }
    },

    getUserData() {
      if (this.codigo) {
        this.fetchUserData();
      }
    },

    async fetchUserData(){

      try{
        let username = '';

        switch(this.isSocioNegocio){
          case 1:
            username = this.codigo;
          break;
          case 2: //aduana
            username = this.editedItem.delegacionAduana.CardCode;
          break;
          case 3: //anmac
            username = this.editedItem.delegacionAnmac.Code;
          break;
        }
        Swal.alertGetInfo("Buscando datos del usuario");
        const [response1] = await Promise.all([
            this.$axiosApi.getByCriteria(this.routeBp, username), //verificar info en bussinesspartner
          ]);

          // Verificamos que ambas peticiones devuelvan datos

          if (response1?.data?.data && typeof response1.data.data === "object" && !Array.isArray(response1.data.data)) {
            Swal.close();

            //if(this.editedIndex ===-1 ){
              this.textInfoDialog = `¿Desea utilizar los datos de <b>${response1.data.data.CardName} (${response1.data.data.CardCode})</b>?`;
            /*}
            else{
              this.textInfoDialog = `¿Desea utilizar los datos de <b>${response1.data.data.CardName} (${response1.data.data.CardCode})?. Recuerde que el nombre de usuario no se puede editar, se mantendra el existente. </b>`;
            }*/

            this.dialogs.emularUsuario = true;
            this.userSAP = response1.data.data;

          } else {
            Swal.close();
            this.dialogs.userExists = true;
          }
            } catch (error) {
              Swal.close();
              console.error(error);
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No se encontraron los datos requeridos.',
              });
            }

        },


        async ruleUniqueEmail(value) {
          if (typeof value !== "undefined" && value != null && value !== "") {
            this.customErrorEmailMessage = "";
            let resp = await this.$isUser(value);

            if (resp === true) {
              this.customErrorEmailMessage =
                "El email ingresado ya está registrado. Por favor, ingrese un email diferente";
              //this.editedItem.datos_usuario.usuario = "";
              return false;
            } else {
              this.customErrorEmailMessage = "";
              return true;
            }
          } else {
            this.customErrorEmailMessage = "";
          }
        },

        async ruleUniqueEmailEdition(value) {
          if (typeof value !== "undefined" && value != null && value !== "") {
            this.customErrorEmailMessage = "";
            let resp = await this.$isUserEdition(value);

            if (resp === false) {
              this.customErrorEmailMessage = "";
              return true;
            }
            else if (resp == this.editedIndex){
              this.customErrorEmailMessage = "";
              return true;
            }
            else{
              this.customErrorEmailMessage =
                "El email ingresado ya está registrado. Por favor, ingrese un email diferente";
              return false
            }

          } else {
            this.customErrorEmailMessage = "";
          }
        },

        async ruleUniqueUsername(value) {
          if (typeof value !== "undefined" && value != null && value !== "") {
            this.customErrorUsernameMessage = "";
            let resp = await this.$isUserByUsername(value);
            console.log("resp");
            console.log(value);
            console.log(resp);
            if (resp === true) {
              this.customErrorUsernameMessage =
                "El nombre de usuario ingresado ya está registrado. Por favor, ingrese un nombre de usuario diferente";
              //this.editedItem.datos_usuario.usuario = "";
              return false;
            } else {
              this.customErrorUsernameMessage = "";
              return true;
            }
          } else {
            this.customErrorUsernameMessage = "";
          }
        },

        async ruleUniqueUsernameEdition(value) {
          if (typeof value !== "undefined" && value != null && value !== "") {
            this.customErrorUsernameMessage = "";
            let resp = await this.$isUserByUsernameEdition(value);

            if (resp === false) {
              this.customErrorUsernameMessage = "";
              return true;
            }
            else if (resp == this.editedIndex){
              this.customErrorUsernameMessage = "";
              return true;
            }
            else{
              this.customErrorUsernameMessage =
                "El nombre de usuario ingresado ya está registrado. Por favor, ingrese un nombre de usuario diferente";
              //this.editedItem.datos_usuario.usuario = "";
              return false
            }
           /*
            if (resp === true) {
              this.customErrorUsernameMessage =
                "El nombre de usuario ingresado ya está registrado. Por favor, ingrese un nombre de usuario diferente";
              //this.editedItem.datos_usuario.usuario = "";
              return false;
            } else {
              this.customErrorUsernameMessage = "";
              return true;
            }*/
          } else {
            this.customErrorUsernameMessage = "";
          }
        },

        formatItemText(item) {
          if(this.isSocioNegocio == 3){
            return `${item.Code} - ${item.Name}`;
          }

          return `${item.CardCode} - ${item.CardName}`;

        },


  },

  mounted() {

    console.log("Componente " + this.route + " creado");
  },
};
</script>

<style>

.card-border {
  border: 1.5px solid #DCDCDC !important
}

.card-span {
  margin-top: -15px;
  position: absolute;
  margin-left: 23px;
  background: white;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 18px;
  font-weight: 500;

}

.custom-btn {
  text-transform: none;
  font-family: inherit;
}

.custom-btn2 {
  text-transform: none;
  font-family: inherit;
  height: 39px !important;
  width: 19px !important;
  padding: 0px !important;
  margin: 0px;
}


.capitalize-first::first-letter {
  text-transform: capitalize;
}
.filtros-trans {
  margin-bottom: 4px;
}

.text-fields-row {
  display: flex;
}

.x-btn-grid {
  flex: unset;
  background-color: bisque;
  margin: 0 0px;
}

.acciones-width {
  width: 150px;
}

.swal2-container {
  z-index: 999999 !important;
}

.hint-celeste .v-messages__message {
  color: #1976D2 !important;
}

.check-codigo-cliente
{
  background-color:#ff0000;
}
/*
::v-deep(.v-messages__message) {
  color: #1976D2 !important;
}
*/
</style>
